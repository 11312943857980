import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import Modal from 'components/Modal';
import { API_RESPONSE } from 'api/_const';
import { useAPI } from 'utils/api';
import { COUPON_STATUS } from 'utils/constants/couponStatus';
import { callAppFunc } from 'utils/jsbridge';
import ModalCouponError from 'components/cart/modal-coupon-error';

import IconExample from 'images/icons/example.svg';
import IconClose from 'images/icons/close.svg';
import IconClearInput from 'images/icons/i-cross-circle-gray.svg';

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div``;

const CloseBtn = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`;
const InputCouponWrapper = styled.div`
  position: relative;
  > img {
    width: 15px;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
`;

const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background-color: #fff;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  outline: none;
  box-sizing: border-box;
`;

const CouponList = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CouponItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const NoticeText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #666;
  padding-bottom: 12px;
  border-bottom: 1px solid #e3e3e3;
  height: 150px;
  overflow-y: scroll;
  &.hide {
    display: none;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0 4px;

  input {
    flex: 0 0 16px;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
    background-color: #fff;
    position: relative;
    top: 2px;

    &:checked {
      background-color: #5fd2da;
      border: 1px solid #5fd2da;
    }

    &::after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  label {
    font-size: 16px;
    font-weight: 700;
    color: #5fd2da;
  }
`;

const CardGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  padding-left: 20px;
`;

const Card = styled.div`
  border-radius: 8px;
  border: 1px solid #f2f2f2;
`;

const CardTitle = styled.div`
  background-color: #f2f2f2;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 700;
  color: #666;
`;

const CardContent = styled.div`
  padding: 4px 8px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  color: #666;
`;

const ModalCoupon = (props) => {
  const {
    setShowEmail,
    isOpen,
    onCancel,
    getCartFinalStep,
    removeCoupons,
    discountList,
  } = props;
  const api = useAPI();

  const couponErrorMsg = useSelector((state) =>
    getters.getCouponErrorMsg(state)
  );
  const [couponCode, setCouponCode] = useState('');
  const [couponTicket, setCouponTicket] = useState({});
  const [myCouponList, setMyCouponList] = useState([]);
  const [couponRule, setCouponRule] = useState('');
  const [isRemoveCoupon, setIsRemoveCoupon] = useState(false);
  const [isError, setIsError] = useState(false);
  const [updateCoupon, setUpdateCoupon] = useState(false);

  const getCartCoupon = () => {
    api
      .getCartCoupon()
      .then((res) => {
        setMyCouponList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCouponRule = () => {
    api
      .getCouponRule()
      .then((res) => {
        if (!res) return;
        setCouponRule(res.content);
      })
      .catch((error) => {});
  };

  const applyCoupon = () => {
    if (couponCode !== '' && Object.keys(couponTicket).length !== 0) {
      // 在這裡處理非空物件的邏輯
      callAppFunc('showAlertView', {
        msg: '折扣碼欄位與折價券欄位不可同時使用',
      });
    } else if (couponCode === '' && Object.keys(couponTicket).length === 0) {
      // 在這裡處理空物件的邏輯
      if (isRemoveCoupon) {
        removeCoupons();
        return;
      }
      callAppFunc('showAlertView', {
        msg: '請選擇折價券或輸入折扣碼',
      });
    } else {
      // 在這裡處理空物件的邏輯
      const code = couponCode !== '' ? couponCode : couponTicket?.couponCode;
      const isUseCouponCode = couponCode !== '' ? true : false;
      const couponEndDate = couponTicket?.couponEndDate;
      api
        .coupons({ code, isUseCouponCode, couponEndDate })
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            setIsError(false);
            if (getCartFinalStep) {
              getCartFinalStep();
            }
            if (onCancel) {
              onCancel();
            }
          } else if (res.code === API_RESPONSE.COUPON_EMAIL_COMBINE) {
            setIsError(false);
            if (onCancel) {
              setShowEmail(true);
              onCancel();
            }
          } else {
            // callAppFunc('showAlertView', {
            //   msg: res.reason,
            // });
            setIsError(true);
            // onCancel();
          }
          getCartCoupon();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    setIsError(false);
    getCouponRule();
  }, []);

  useEffect(() => {
    setCouponTicket({});
    if (isOpen) {
      getCartCoupon();
    }
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (updateCoupon) {
        setUpdateCoupon(false);
      }
    }, 500);
  }, [updateCoupon]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        <TitleWrapper>
          <Title>選擇折價券或輸入折扣碼</Title>
          <CloseBtn onClick={onCancel}>
            <img src={IconClose} />
          </CloseBtn>
        </TitleWrapper>
      }
      cancelText="跳過"
      onCancel={onCancel}
      onOk={() => applyCoupon()}
    >
      <Content>
        <InputCouponWrapper>
          <Input
            placeholder="請輸入折扣碼"
            enterKeyHint="done"
            value={couponCode}
            onClick={() => {
              if (Object.keys(couponTicket).length !== 0) {
                setCouponTicket({});
                setUpdateCoupon(true);
              }
            }}
            onChange={(e) => setCouponCode(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.target.blur();
              }
            }}
          />
          {couponCode && (
            <img
              src={IconClearInput}
              onClick={() => {
                setIsError(false);
                setCouponCode('');
              }}
            />
          )}
        </InputCouponWrapper>

        {!updateCoupon && myCouponList.length > 0 ? (
          <CouponList>
            {myCouponList.map((coupon, idx) => {
              return (
                <CouponItem key={idx}>
                  <RadioWrapper>
                    <input
                      type="radio"
                      id="a"
                      name="coupon-option"
                      onChange={(e) => {
                        setIsError(false);
                        setCouponCode('');
                        setCouponTicket(coupon);
                        setIsRemoveCoupon(false);
                      }}
                    />
                    <label htmlFor="a">{coupon?.couponTitle}</label>
                  </RadioWrapper>

                  <CardGroup>
                    <Card>
                      <CardTitle>使用限制</CardTitle>
                      <CardContent>{coupon?.couponDescription}</CardContent>
                    </Card>
                    <Card>
                      <CardTitle>使用期限</CardTitle>
                      <CardContent>{`${coupon?.couponStartDate} ～ ${coupon?.couponEndDate}`}</CardContent>
                    </Card>
                  </CardGroup>
                </CouponItem>
              );
            })}
            {discountList?.filter((discount) => discount.type === 'coupon')
              .length !== 0 && (
              <CouponItem>
                <RadioWrapper>
                  <input
                    type="radio"
                    id="a"
                    name="coupon-option"
                    onChange={() => {
                      setIsError(false);
                      setCouponCode('');
                      setCouponTicket({});
                      setIsRemoveCoupon(true);
                    }}
                  />
                  <label htmlFor="a">不使用任何折價券/折扣碼</label>
                </RadioWrapper>
              </CouponItem>
            )}
          </CouponList>
        ) : (
          <CouponItem>
            <RadioWrapper>
              <input
                type="radio"
                id="a"
                name="coupon-option"
                onChange={() => {
                  setIsError(false);
                  setCouponCode('');
                  setCouponTicket({});
                  setIsRemoveCoupon(true);
                }}
              />
              <label htmlFor="a">不使用任何折價券/折扣碼</label>
            </RadioWrapper>
          </CouponItem>
        )}

        <NoticeText>{parse(couponRule)}</NoticeText>

        <ModalCouponError
          isOpen={isError}
          text={couponErrorMsg}
          onOk={() => setIsError(false)}
        ></ModalCouponError>
      </Content>
    </Modal>
  );
};

export default ModalCoupon;
